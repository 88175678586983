import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useCallback, useEffect, useRef, useState } from 'react';

import Button from '@/components/ui/buttons/Button';
import BrandBlock from '@/components/ui/cards/insider/BrandBlock';

interface Brand {
  imageUrl: string;
  buttonText: string;
  buttonUrl: string;
  altText?: string;
  wordmark?: string;
  brandText?: string;
}

interface InsiderBrandsProps {
  brands: Brand[];
}

export default function InsiderBrands({ brands }: InsiderBrandsProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);

  const getSlideWidth = useCallback(() => {
    if (!sliderRef.current?.children[0]) return 0;
    const article = sliderRef.current.children[0] as HTMLDivElement;
    const gap = 24;
    return article.offsetWidth + gap;
  }, []);

  const handleNext = useCallback(() => {
    setCurrentIndex((current) => (current >= brands.length - 4 ? 0 : current + 1));
  }, [brands.length]);

  const handlePrev = useCallback(() => {
    setCurrentIndex((current) => (current <= 0 ? brands.length - 4 : current - 1));
  }, [brands.length]);

  useEffect(() => {
    const slideWidth = getSlideWidth();
    if (sliderRef.current) {
      sliderRef.current.style.transform = `translateX(-${currentIndex * slideWidth}px)`;
    }
  }, [currentIndex, getSlideWidth]);

  return (
    <div className='border border-neutral-200 bg-neutral-50'>
      <div className='container mx-auto mt-2 px-4 pb-4 lg:mt-6 lg:max-w-screen-xl lg:pb-6 flex flex-col items-center gap-8 py-12 text-center'>
        <div className='flex items-center gap-4'>
          <h3 className='hidden font-robotoCondensed text-4xl font-bold uppercase leading-10 text-neutral-950 lg:block'>
            Hook & Barrel
          </h3>
          <img
            src='https://images.hookandbarrel.com/prod/icons/insider.svg'
            className='mx-auto h-12 w-auto'
            alt='Insider logo'
          />
        </div>

        <span className='font-acuminPro text-base font-semibold uppercase leading-normal text-neutral-800'>
          Daily Exclusive Updates from Firearm and Accessory Brands You Love
        </span>

        <div className='relative w-full'>
          <div className='w-full overflow-x-hidden scroll-smooth'>
            <div
              ref={sliderRef}
              className='hidden space-x-6 transition-transform duration-300 lg:flex'
              style={{ transform: `translateX(-${currentIndex * getSlideWidth()}px)` }}
            >
              {brands.map((brand) => (
                <div key={brand.buttonUrl} className='w-[calc((100%-72px)/4)] flex-shrink-0'>
                  <BrandBlock {...brand} openInNewTab={false} />
                </div>
              ))}
            </div>
          </div>

          <div className='hidden lg:block'>
            <Button
              intent='navigationLight'
              size='navigation'
              className='z-5 absolute left-2 top-[11.5rem] -translate-y-full'
              onClick={handlePrev}
            >
              <ChevronLeftIcon className='h-8 w-8' />
            </Button>
          </div>

          <div className='hidden lg:block'>
            <Button
              intent='navigationLight'
              size='navigation'
              className='z-5 absolute right-2 top-[11.5rem] -translate-y-full'
              onClick={handleNext}
            >
              <ChevronRightIcon className='h-8 w-8' />
            </Button>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4 lg:hidden'>
          {brands.map((brand) => (
            <BrandBlock key={brand.buttonUrl} {...brand} />
          ))}
        </div>

        <a href='/insider'>
          <Button intent='dark' size='default'>
            Visit Insider
          </Button>
        </a>
      </div>
    </div>
  );
}
