import ReactImage from '@/components/ReactImage';

import Button from '../../buttons/Button';

interface BrandBlockProps {
  imageUrl: string;
  buttonText: string;
  buttonUrl: string;
  altText?: string;
  wordmark?: string;
  logo?: string;
  brandText?: string;
  openInNewTab?: boolean;
  children?: React.ReactNode;
}

function BrandBlock({
  imageUrl,
  buttonText,
  buttonUrl,
  altText = '',
  wordmark,
  brandText,
  openInNewTab,
  children,
}: BrandBlockProps) {
  return (
    <div className='flex flex-col items-center gap-4 border border-neutral-200 bg-white pb-4'>
      <a href={buttonUrl} target={openInNewTab ? '_blank' : '_self'}>
        <div className='aspect-[3/2] w-full overflow-hidden'>
          <ReactImage
            src={imageUrl}
            alt={altText}
            layout='constrained'
            width={1800}
            height={1200}
            className='h-full w-full object-cover'
          />
        </div>
      </a>
      <div className='text-center'>
        {wordmark ? (
          <img src={wordmark} alt={altText} className='mx-auto h-14 w-auto max-w-[65%] object-contain' />
        ) : brandText ? (
          <p className='font-robotoCondensed text-2xl font-medium uppercase leading-loose text-neutral-950'>
            {brandText}
          </p>
        ) : (
          children
        )}
      </div>
      <a href={buttonUrl} target={openInNewTab ? '_blank' : '_self'}>
        <Button intent='secondary' size='default'>
          {buttonText}
        </Button>
      </a>
    </div>
  );
}

export default BrandBlock;
